.app-loader {
  left: 0;
  top: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
