* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
  padding-bottom: 10px;
}

body {
  padding: 0;
  margin: 0;
  min-width: 320px;
}

.container {
  margin: auto;
  max-width: 980px;
  padding-left: 20px;
  padding-right: 20px;
}

.is-hidden {
  display: none !important;
}

.no-scroll {
  overflow: hidden !important;
}